import { default as daily_45statswqgrSzZgnyMeta } from "/home/runner/work/service-waitlister/service-waitlister/pages/admin/daily-stats.vue?macro=true";
import { default as register_45invite_45clientsmDKeqS36xyMeta } from "/home/runner/work/service-waitlister/service-waitlister/pages/admin/register-invite-clients.vue?macro=true";
import { default as registered_45clientslDbL7CUo30Meta } from "/home/runner/work/service-waitlister/service-waitlister/pages/admin/registered-clients.vue?macro=true";
import { default as special_45codesVciKMcjrARMeta } from "/home/runner/work/service-waitlister/service-waitlister/pages/admin/special-codes.vue?macro=true";
import { default as indexCwkB5Wn3XeMeta } from "/home/runner/work/service-waitlister/service-waitlister/pages/index.vue?macro=true";
import { default as mobile_45appJIv8iuCxBIMeta } from "/home/runner/work/service-waitlister/service-waitlister/pages/mobile-app.vue?macro=true";
export default [
  {
    name: "admin-daily-stats",
    path: "/admin/daily-stats",
    meta: daily_45statswqgrSzZgnyMeta || {},
    component: () => import("/home/runner/work/service-waitlister/service-waitlister/pages/admin/daily-stats.vue")
  },
  {
    name: "admin-register-invite-clients",
    path: "/admin/register-invite-clients",
    meta: register_45invite_45clientsmDKeqS36xyMeta || {},
    component: () => import("/home/runner/work/service-waitlister/service-waitlister/pages/admin/register-invite-clients.vue")
  },
  {
    name: "admin-registered-clients",
    path: "/admin/registered-clients",
    meta: registered_45clientslDbL7CUo30Meta || {},
    component: () => import("/home/runner/work/service-waitlister/service-waitlister/pages/admin/registered-clients.vue")
  },
  {
    name: "admin-special-codes",
    path: "/admin/special-codes",
    meta: special_45codesVciKMcjrARMeta || {},
    component: () => import("/home/runner/work/service-waitlister/service-waitlister/pages/admin/special-codes.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/service-waitlister/service-waitlister/pages/index.vue")
  },
  {
    name: "mobile-app",
    path: "/mobile-app",
    meta: mobile_45appJIv8iuCxBIMeta || {},
    component: () => import("/home/runner/work/service-waitlister/service-waitlister/pages/mobile-app.vue")
  }
]